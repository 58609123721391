html[data-theme="dark"] {
  --foreground: #FFFFFF;
  --background: #030D20;

  --accent: var(--pink-500);
  --accent-gradient-from: #BD8EF6;
  --accent-gradient-to: var(--pink-400);
  --secondary-accent: var(--pink-400);
  --secondary-accent-gradient-from: #9E8EF6;
  --secondary-accent-gradient-via: #EE6EB3B0;
  --secondary-accent-gradient-to: #EE3894;

  /* main */
  --primary: var(--foreground);
  --primary-background: var(--primary);
  --primary-background-hover: #FFFFFFB2;
  --primary-background-disabled: var(--white-500);
  --primary-border: var(--white-700);
  --primary-border-disabled: var(--primary-disabled);
  --primary-border-hover: var(--foreground);
  --primary-disabled: var(--white-600);
  --primary-disabled-blended: #616771;
  --primary-separator: var(--primary);

  .page-background {
    background: url('/assets/dark-noise-radial-gradient.svg') no-repeat center center;
    background-size: 100vw 100vh;
  }

  /* greyscale */
  --white: #FFFFFF;
  --black: #0D0D0D;
  --grey-100: #F0F0F0;
  --grey-200: #E4E4E7;
  --grey-300: #C7C7C7;
  --grey-400: #828282;
  --grey-500: #494949;
  --grey-600: #2A2A2A;

  /* whitescale */
  --white-100: #FFFFFF0F;
  --white-200: #FFFFFF14;
  --white-300: #FFFFFF1F;
  --white-400: #FFFFFF29;
  --white-500: #FFFFFF3D;
  --white-600: #FFFFFF52;
  --white-700: #FFFFFF66;

  /* pinkscale */
  --pink-100: #FFEBF5;
  --pink-200: #FFBEDF;
  --pink-300: #FF91C8;
  --pink-400: #FF65B2;
  --pink-500: #ED3491;
  --pink-600: #CB2277;
  --pink-700: #A9135E;
  --pink-800: #870848;
  --pink-900: #650033;

  /* colorscale */
  --color-100: var(--pink-100);
  --color-200: var(--pink-200);
  --color-300: var(--pink-300);
  --color-400: var(--pink-400);
  --color-500: var(--pink-500);
  --color-600: var(--pink-600);
  --color-700: var(--pink-700);
  --color-800: var(--pink-800);
  --color-900: var(--pink-900);

  /* Secondary */
  --secondary: var(--grey-300);
  --secondary-background: #2B3444; /* white-400 blended on background */
  --secondary-background-disabled: #172031; /* white-200 blended on background */
  --secondary-background-transparent: var(--white-400);
  --secondary-background-hover: var(--white-200);
  --secondary-border: #686e79; /* white-700 blended on background */
  --secondary-border-disabled: var(--primary-border-disabled);
  --secondary-separator: #4b505e; /* white-500 blended on background */
  --secondary-text: var(--white-700);

  /* Tertiary */
  --tertiary-background: var(--background);
  --tertiary-background-disabled: var(--tertiary-background);
  --tertiary-border: var(--primary-border);
  --tertiary-border-disabled: var(--primary-border-disabled);

  /* Success */
  --success-primary: #37CD7C;
  --success-secondary: #37CD7C7A;
  --success-tertiary: #37CD7C52;
  --success-quaternary: #37CD7C29;
  --success-quinary: #37CD7C14;
  --success: var(--success-primary);
  --success-disabled: var(--success-tertiary);
  --success-background: var(--success-quaternary);
  --success-background-disabled: var(--success-quaternary);
  --success-border: var(--success-tertiary);
  --success-border-disabled: var(--success-tertiary);

  /* Warning */
  --warning-primary: #EF9038;
  --warning-secondary: #EF90387A;
  --warning-tertiary: #EF903852;
  --warning-quaternary: #EF903829;
  --warning-quinary: #EF903814;
  --warning: var(--warning-primary);

  /* Error */
  --error-primary: #F4434E;
  --error-secondary: #F4434E7A;
  --error-tertiary: #F4434E52;
  --error-quaternary: #F4434E29;
  --error-quinary: #F4434E14;
  --error: var(--error-primary);
  --error-disabled: var(--error-tertiary);
  --error-background: var(--error-quaternary);
  --error-background-disabled: var(--error-quaternary);
  --error-border: var(--error-tertiary);
  --error-border-disabled: var(--error-tertiary);

  --radius: 0.5rem;

  /* Cards */
  --card-background: var(--white-100);
  --card-background-blended: #171d2a;
  --card-border: var(--white-300);
  --card-hover: var(--white-200);

  /* Badges */
  --badge-primary: var(--white-300);
  --badge-primary-disabled: var(--white-200);

  /* Menu */
  --menu-item-background-active: var(--white-600);

  /* Element colors */
  --subtext: var(--grey-300);
  --subtext-disabled: var(--grey-400);
  --ring: var(--primary);

  /* Element metrics */
  --ring-width-1: 1px;
  --ring-width-2: 1px;
  --ring-offset-width-1: 2px;
  --ring-offset-width-2: 3px;

  /* Typography */

  --lato-bold: 700;
  --lato-semibold: 700;
  --lato-normal: 400;

  --kode-mono-bold: 700;
  --kode-mono-semibold: 600;
  --kode-mono-regular: 400;

  h1, h2, h3, h4, h5, h6 {
    font-weight: var(--lato-bold);
  }
  .subtitle-1, .subtitle-2, .subtitle-3 {
    font-weight: var(--lato-semibold);
  }
  .body-1, .body-2, .body-3, .body-4 {
    font-weight: var(--lato-normal);
  }
  .menu-1 {
    font-weight: var(--kode-mono-semibold);
  }
  .menu-2 {
    font-weight: var(--kode-mono-regular);
  }

  /* Fixes the light background in input fields on autofill (works on Chrome) */
  input {
    color-scheme: dark;
  }

  /* Avoid white screen while loading the page */
  body {
    background-color: var(--background);
  }
}
